
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const { public: { firebase: firebaseConfig } } = useRuntimeConfig()

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

//initialize firebase auth
export const auth = getAuth(app);

//initialize firestore
export const db = getFirestore(app);
